import Grid from "@material-ui/core/Grid";
import Stars3 from "../images/stars/stars3.png";
import Stars4 from "../images/stars/stars4.png";

import "../styles/links.scss";

import TubbyLogo from "../images/tubby_logo00.svg";

const Links = () => {
  return (
    <div className="Linksksks">
      <div className="BorderContainer">
        <Grid container className="AboutHeaderContainer">
          <Grid item xs={2} style={{ position: "relative" }}></Grid>
          <Grid item xs={8}>
            <div className="LinksHeader">
              <img src={TubbyLogo} />
            </div>
            <a
              href="https://tubbycats.shop/"
              target="_blank"
              rel="noreferrer"
              style={{ textDecoration: "none" }}
            >
              <button className="button-56">
                <p>Official Merch Shop</p>
              </button>
            </a>
            <a
              href="https://hub.vroid.com/en/users/92407931"
              target="_blank"
              rel="noreferrer"
              style={{ textDecoration: "none", color: "white" }}
            >
              <button className="button-56">
                <p>3D Tubby Cat Files</p>
              </button>
            </a>
            <p className="buttonsan">
              *Note: create an account on{" "}
              <a
                href="https://hub.vroid.com/en"
                target="_blank"
                rel="noreferrer"
                style={{ textDecoration: "underline", color: "white" }}
              >
                vroid
              </a>{" "}
              and login to download Tubby Cat VRM files.
            </p>
            <a
              href="https://opensea.io/collection/tubby-cats"
              target="_blank"
              rel="noreferrer"
              style={{ textDecoration: "none" }}
            >
              <button className="button-56" style={{ marginTop: "20px" }}>
                <p>OpenSea</p>
              </button>
            </a>
            <a
              href="https://looksrare.org/collections/0xCa7cA7BcC765F77339bE2d648BA53ce9c8a262bD"
              target="_blank"
              rel="noreferrer"
              style={{ textDecoration: "none" }}
            >
              <button className="button-56">
                <p>Looksrare</p>
              </button>
            </a>
            <button className="button-56">
              <a
                href="https://discord.com/invite/tubbycatsnft"
                target="_blank"
                rel="noreferrer"
                style={{ textDecoration: "none" }}
              >
                <p>Discord</p>
              </a>
            </button>
            <button className="button-56">
              <a
                href="https://twitter.com/tubbycatsnft"
                target="_blank"
                rel="noreferrer"
                style={{ textDecoration: "none" }}
              >
                <p>Twitter</p>
              </a>
            </button>
          </Grid>
          <Grid item xs={2} style={{ position: "relative" }}></Grid>
        </Grid>
      </div>
    </div>
  );
};
export default Links;
