import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import Header from "./components/Header";
import Home from "./pages/Home";
import About from "./pages/About";
import Links from "./pages/Links";
import Nyc from "./pages/Nyc";
import Honoraries from "./pages/Honoraries";
import { WalletSelectorModal } from "./lib/WalletSelector/modal";
import { DAppProvider } from "@usedapp/core";
import { DAppConfig } from "./lib/web3";
import { WalletSelectorContextProvider } from "./lib/WalletSelector/context";

const App = () => {
  return (
    <div>
      <BrowserRouter>
        {/* <Header /> */}
        <Switch>
          <Route exact path="/" render={() => <Redirect to="/home" />} />
          <Route path="/home" component={Home} />
          <Route path="/about" component={About} />
          <Route path="/links" component={Links} />
          <Route path="/honoraries" component={Honoraries} />
          <Route path="/nyc" component={Nyc} />
        </Switch>
      </BrowserRouter>
    </div>
  );
};

export default App;
