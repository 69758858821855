import Grid from "@material-ui/core/Grid";

// images
import TubbyPlanet from "../images/tubby_planet.png";
import NYCLogo from "../images/nyclogo.png";

const Nyc = () => {
  return (
    <div style={{ background: "#131739" }}>
      <div
        className="BorderContainer"
        style={{
          paddingTop: "140px",
        }}
      >
        <img
          src={TubbyPlanet}
          alt="tubby planet"
          style={{
            width: "40%",
            maxWidth: "180px",
            margin: "0 auto",
            display: "block",
          }}
        />
        <Grid container className="AboutHeaderContainer">
          {/* <Grid item xs={2} style={{ position: "relative" }}></Grid> */}
          <Grid item xs={12}>
            <h1 className="EventHeader">Tubby Cats go to NYC!</h1>
          </Grid>
          {/* <Grid item xs={2} style={{ position: "relative" }}></Grid> */}
        </Grid>
        <Grid
          container
          className="EventButtonContainer"
          style={{ marginTop: "10px" }}
        >
          <Grid item xs={12} sm={6}>
            <a
              href="https://www.eventbrite.com/e/tubby-cats-go-to-nyc-tickets-353305645697"
              target="_blank"
              rel="noreferrer"
              style={{ color: "white", textDecoration: "none" }}
            >
              <button className="EventButton">
                <p>RSVP on EventBrite</p>
              </button>
            </a>
          </Grid>
          <Grid item xs={12} sm={6}>
            <a
              href="/flyer.png"
              target="_blank"
              rel="noreferrer"
              style={{ color: "white", textDecoration: "none" }}
            >
              <button className="EventButton">
                <p>View Event Flyer</p>
              </button>
            </a>
          </Grid>
        </Grid>
        <Grid container>
          <Grid
            item
            xs={12}
            sm={10}
            md={8}
            style={{
              textAlign: "center",
              margin: "0 auto",
              marginTop: "10px",
            }}
          >
            <p className="EventText">
              join tubby cats at our first ever event during NFT NYC at{" "}
              <a
                href="https://www.google.com/maps/place/Bogart+House/@40.7116634,-73.9354629,15z/data=!4m5!3m4!1s0x0:0xe5df5808ac868af0!8m2!3d40.7116634!4d-73.9354629"
                target="_blank"
                rel="noreferrer"
                style={{ color: "white" }}
              >
                {" "}
                bogart house
              </a>{" "}
              on june 22nd from 7pm - 1am in brooklyn, nyc!
            </p>
            <p className="EventText">
              come enjoy an evening with tubby cats with an open bar (drinks on
              us!), activities, dancing, merch & more!
            </p>
            <p className="EventText">
              entry for tubby cat holders admission is FREE (verified via{" "}
              <a
                href="https://linktr.ee/tokenproof"
                target="_blank"
                rel="noreferrer"
                style={{ color: "white" }}
              >
                tokenproof
              </a>{" "}
              ). holders may also include a +1 upon entry free of charge. +1's
              must have a valid ticket from{" "}
              <a
                href="https://www.eventbrite.com/e/tubby-cats-go-to-nyc-tickets-353305645697"
                target="_blank"
                rel="noreferrer"
                style={{ color: "white" }}
              >
                eventbrite
              </a>{" "}
              (these tickets are free.)
            </p>
            <p className="EventText">
              general admission is $30. tickets can be purchased on{" "}
              <a
                href="https://www.eventbrite.com/e/tubby-cats-go-to-nyc-tickets-353305645697"
                target="_blank"
                rel="noreferrer"
                style={{ color: "white" }}
              >
                eventbrite
              </a>
              .
            </p>
            <p className="EventText">
              all guests must present a valid eventbrite ticket or tokenproof
              verification to enter.
            </p>
            <p className="EventHeader EventHeader2">
              ✨ verify via tokenproof ✨
            </p>
            <p className="EventText">
              1. download the tokenproof app (
              <a
                href="https://apps.apple.com/us/app/tokenproof/id1605810422"
                target="_blank"
                rel="noreferrer"
                style={{ color: "white" }}
              >
                app store
              </a>{" "}
              or{" "}
              <a
                href="https://play.google.com/store/apps/details?id=xyz.tokenproof.wallet"
                target="_blank"
                rel="noreferrer"
                style={{ color: "white" }}
              >
                google play
              </a>
              ) - this will be your key to accessing the event.
            </p>
            <p className="EventText">
              2. register with tokenproof! all holders planning to go to our
              event will be required to first enroll with tokenproof using{" "}
              <a
                href="https://www.tokenproof.xyz/enrollment"
                target="_blank"
                rel="noreferrer"
                style={{ color: "white" }}
              >
                this link
              </a>
              .
            </p>
            <p className="EventText">
              tokenproof step by step instructions:{" "}
              <a
                href="https://tinyurl.com/tokenproofmobileregistration"
                target="_blank"
                rel="noreferrer"
                style={{ color: "white" }}
              >
                mobile
              </a>{" "}
              or{" "}
              <a
                href="https://tinyurl.com/tokenproofdesktopregistration"
                target="_blank"
                rel="noreferrer"
                style={{ color: "white" }}
              >
                desktop
              </a>
            </p>
            <p className="EventText">
              NOTE: please make sure you register with the wallet that is
              holding your tubby cat(s)!
            </p>
            <p className="EventText" style={{ opacity: 0.8 }}>
              <span style={{ fontWeight: 600 }}>special notes:</span> <br></br>
              ticket does not guarantee entry (event capacity is 300)<br></br>
              this event is 21+
            </p>
            <br></br>
            <a
              href="https://discord.gg/tubbycatsnft"
              target="_blank"
              rel="noreferrer"
              style={{ color: "white", cursor: "pointer", fontWeight: 400 }}
            >
              <p className="EventText">join our discord for more info!</p>
            </a>
          </Grid>
        </Grid>
      </div>
      <img
        src={NYCLogo}
        alt="tubby nyc logo"
        style={{
          width: "40%",
          maxWidth: "200px",
          margin: "0 auto",
          marginTop: "80px",
          display: "block",
        }}
      />
      <div style={{ height: "140px" }}></div>
    </div>
  );
};
export default Nyc;
