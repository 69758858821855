import Grid from "@material-ui/core/Grid";
import Stars3 from "../images/stars/stars3.png";
import Stars4 from "../images/stars/stars4.png";
import Gif from "../images/tubbyGif.gif";

// images
import Ratty from "../images/team/ratty.png";
import TrollDart from "../images/team/trolldart.png";
import Hoppy from "../images/team/hoppy.png";
import VKR from "../images/team/vkr.png";
import Sugoi from "../images/team/sugoi.png";
import Bread from "../images/team/bread.png";
import Bandage from "../images/team/bandage.png";

const About = () => {
  return (
    <div>
      <div
        className="BorderContainer"
        style={{
          marginTop: "100px",
        }}
      >
        <Grid container className="AboutHeaderContainer">
          <Grid item xs={2} style={{ position: "relative" }}>
            <img src={Stars4} alt="Stars" className="StarsLeft" />
          </Grid>
          <Grid item xs={8}>
            <h1 className="AbtHeader">About Tubby Cats</h1>
          </Grid>
          <Grid item xs={2} style={{ position: "relative" }}>
            <img src={Stars3} alt="Stars" className="StarsRight" />
          </Grid>
        </Grid>
        <Grid container spacing={4} className="AboutAboutContainer">
          <Grid item xs={12} sm={7}>
            <h2>The Collection</h2>
            <p>
              tubby cats is a collection of 20000 algorithmically generated and
              hand-created nfts on etherium.
            </p>
            <p className="TeamAbout">
              art:{" "}
              <a
                href="https://twitter.com/tubbystudios"
                target="_blank"
                rel="noreferrer"
              >
                @tubbystudios
              </a>{" "}
            </p>
            <p className="TeamAbout">
              code:{" "}
              <a
                href="https://twitter.com/guccitroII"
                target="_blank"
                rel="noreferrer"
              >
                @guccitroll
              </a>
            </p>

            <p className="TeamAbout">
              smart contract:{" "}
              <a
                href="https://twitter.com/0xngmi"
                target="_blank"
                rel="noreferrer"
              >
                @0xngmi
              </a>
            </p>
            {/* <button>
              <p>opensea soon</p>
            </button> */}
          </Grid>
          <Grid item xs={12} sm={5}>
            <img
              src={Gif}
              alt="Tubby Cats Gif"
              style={{ width: "100%" }}
              className="TubbyGif"
            />
          </Grid>
        </Grid>
        <Grid container spacing={4} className="AboutAboutContainer">
          <Grid item xs={12}>
            <h2>creating tubby cats</h2>
            <p style={{ margin: "0 auto" }}>
              rather than generating the collection completely randomly, each
              tubby cat is created from one of 120 various themed palettes.
            </p>
            <p style={{ margin: "0 auto", marginTop: "20px" }}>
              tubby cats are not generated with individual trait rarity in mind.
              rarity is based on the themed gen palettes.
            </p>
            <p style={{ margin: "0 auto", marginTop: "20px" }}>
              the collection also contains 69 one-of-ones, which are created by
              different artists on our team. each one-of-one has an attribute
              that displays the artist of each.
            </p>
          </Grid>
        </Grid>
        <h1 className="AbtHeader">The Team</h1>
        <div className="BorderContainer">
          <Grid
            container
            spacing={2}
            style={{ width: "75%", margin: "0 auto" }}
            className="TeamContainerTop"
          >
            <Grid item xs={12} sm={12} md={4}>
              <div className="TeamContainer1">
                <a
                  href="https://twitter.com/ratweii"
                  target="_blank"
                  rel="noreferrer"
                  style={{ color: "white" }}
                >
                  <div className="TeamCard">
                    <img src={Ratty} alt="ratwell" />
                    <div className="TeamInfo">
                      <div className="CenteredInfo">
                        <p
                          style={{
                            margin: "0 auto",
                            marginBottom: "5px",
                          }}
                          className="Title"
                        >
                          Artist & project lead
                        </p>
                        <h2>@ratwell</h2>
                        <p>
                          Hello based department, calling to notify you that we
                          are impressed with your work.
                        </p>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <div className="TeamContainer1">
                <a
                  href="https://twitter.com/guccitroii"
                  target="_blank"
                  rel="noreferrer"
                  style={{ color: "white" }}
                >
                  <div className="TeamCard">
                    <img src={TrollDart} alt="guccitroll" />
                    <div className="TeamInfo">
                      <div className="CenteredInfo">
                        <p
                          style={{
                            margin: "0 auto",
                            marginBottom: "5px",
                          }}
                          className="Title"
                        >
                          dev
                        </p>
                        <h2>@guccigoshii</h2>
                        <p>(recently deceased) dev</p>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <div className="TeamContainer1">
                <a
                  href="https://twitter.com/sugoiNFT"
                  target="_blank"
                  rel="noreferrer"
                  style={{ color: "white" }}
                >
                  <div className="TeamCard">
                    <img src={Sugoi} alt="Sugoi" />
                    <div className="TeamInfo">
                      <div className="CenteredInfo">
                        <p
                          style={{
                            margin: "0 auto",
                            marginBottom: "5px",
                          }}
                          className="Title"
                        >
                          artist
                        </p>
                        <h2>@sugoiNFT</h2>
                        <p>meowmeowmeowmeowmeowmeow</p>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </Grid>
          </Grid>
        </div>
        <div className="BorderContainer">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={3}>
              <div className="TeamContainer1">
                <a
                  href="https://twitter.com/bandageNFT"
                  target="_blank"
                  rel="noreferrer"
                  style={{ color: "white" }}
                >
                  <div className="TeamCard">
                    <img src={Bandage} alt="bandageNFT" />
                    <div className="TeamInfo">
                      <div className="CenteredInfo">
                        <p
                          style={{
                            margin: "0 auto",
                            marginBottom: "5px",
                          }}
                          className="Title"
                        >
                          artist
                        </p>
                        <h2>@bandageNFT</h2>
                        <p>has a brown cat</p>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={3}>
              <div className="TeamContainer1">
                <div className="TeamCard">
                  <img src={Hoppy} alt="Hoppy" />
                  <div className="TeamInfo">
                    <div className="CenteredInfo">
                      <p
                        style={{
                          margin: "0 auto",
                          marginBottom: "5px",
                        }}
                        className="Title"
                      >
                        artist
                      </p>
                      <h2>@hoppynft</h2>
                      <p>
                        if i was a frog and you were a frog would you let me be
                        a guest on your lily pad yes or no
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={3}>
              <div className="TeamContainer1">
                <a
                  href="https://twitter.com/VKR000"
                  target="_blank"
                  rel="noreferrer"
                  style={{ color: "white" }}
                >
                  <div className="TeamCard">
                    <img src={VKR} alt="VKR" />
                    <div className="TeamInfo">
                      <div className="CenteredInfo">
                        <p
                          style={{
                            margin: "0 auto",
                            marginBottom: "5px",
                          }}
                          className="Title"
                        >
                          artist
                        </p>
                        <h2>@VKR000</h2>
                        <p>8ft tall silent skinless corpse…</p>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={3}>
              <div className="TeamContainer1">
                <a
                  href="https://twitter.com/sweetbread_eth"
                  target="_blank"
                  rel="noreferrer"
                  style={{ color: "white" }}
                >
                  <div className="TeamCard">
                    <img src={Bread} alt="Bread" />
                    <div className="TeamInfo">
                      <div className="CenteredInfo">
                        <p
                          style={{
                            margin: "0 auto",
                            marginBottom: "5px",
                          }}
                          className="Title"
                        >
                          artist
                        </p>
                        <h2>@sweetbread_eth</h2>
                        <p>hi.....................................</p>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
      <div style={{ height: "140px" }}></div>
    </div>
  );
};
export default About;
