import Grid from "@material-ui/core/Grid"
import Stars3 from "../images/stars/stars3.png"
import Stars4 from "../images/stars/stars4.png"
import Loom from "../images/honoraries/loom.png"
import Karbon from "../images/honoraries/karbon.png"
import FishXBT from "../images/honoraries/fishxbt.png"
import Foooo from "../images/honoraries/foooo.png"
import Baphie from "../images/honoraries/baphie.png"
import Nomad from "../images/honoraries/nomad.png"
import RedXBT from "../images/honoraries/redxbt.png"
import ZoZo from "../images/honoraries/zozo.png"
import InverseBrah from "../images/honoraries/inversebrah.png"
import PathHon from "../images/honoraries/path.png"
import CoinAlchemist from "../images/honoraries/coinalchemisttubby.png"
import DegenSpartan from "../images/honoraries/degenSpartanTubbyCatYellow.png"
import Gainzy from "../images/honoraries/gainzy.png"
import Chibben from "../images/honoraries/chibben.png"
import Dogetoshi from "../images/honoraries/Dogetoshi.png"
import Transmission from "../images/honoraries/transmission.png"
import LomahCrypto from "../images/honoraries/loma.png"
import BlockGeekDima from "../images/honoraries/BlockGeekDima.png"
import Sugoi from "../images/honoraries/sugoi.png"
import ColdBloodShill from "../images/honoraries/ColdBloodShill.png"
import Ratty from "../images/honoraries/ratty.jpeg"
import Hoppy from "../images/honoraries/hoppy.jpeg"
import Mons from "../images/honoraries/0xmons.jpeg"
import Bitter from "../images/honoraries/bitter.jpeg"
import StateSlayer from "../images/honoraries/stateslayer.jpeg"
const honMembers = [
  {
    twitterHandle: "ratwell0x",
    _img: Ratty
  },
  {
    twitterHandle: "sugoiNFT",
    _img: Sugoi
  },
  {
    twitterHandle: "hoppynft",
    _img: Hoppy
  },
  {
    twitterHandle: "loomdart",
    _img: Loom
  },
  {
    twitterHandle: "basedkarbon",
    _img: Karbon
  },
  {
    twitterHandle: "bitcoinpanda69",
    _img: Foooo
  },
  {
    twitterHandle: "FishXBT",
    _img: FishXBT
  },
  {
    twitterHandle: "TheBaphie",
    _img: Baphie
  },
  {
    twitterHandle: "bit__ter",
    _img: Bitter
  },
  {
    twitterHandle: "statelayer",
    _img: StateSlayer
  },
  {
    twitterHandle: "redxbt",
    _img: RedXBT
  },
  {
    twitterHandle: "0xMons",
    _img: Mons
  },
  {
    twitterHandle: "IamNomad",
    _img: Nomad
  },
  {
    twitterHandle: "ZoyaHighVibe",
    _img: ZoZo
  },
  {
    twitterHandle: "inversebrah",
    _img: InverseBrah
  },
  {
    twitterHandle: "Cryptopathic",
    _img: PathHon
  },
  {
    twitterHandle: "c0inalchemist",
    _img: CoinAlchemist
  },
  {
    twitterHandle: "Pollo2x",
    _img: Chibben
  },
  {
    twitterHandle: "transmissions11",
    _img: Transmission
  },
  {
    twitterHandle: "DegenSpartan",
    _img: DegenSpartan
  },
  {
    twitterHandle: "gainzxbt",
    _img: Gainzy
  },
  {
    twitterHandle: "Dogetoshi",
    _img: Dogetoshi
  },
  {
    twitterHandle: "LomahCrypto",
    _img: LomahCrypto
  },
  {
    twitterHandle: "BlockGeekDima",
    _img: BlockGeekDima
  },
  {
    twitterHandle: "ColdBloodShill",
    _img: ColdBloodShill
  }
]

const Honoraries = () => {
  return (
    <div>
      <div
        className="BorderContainer"
        style={{
          minHeight: "100vh",
          marginTop: "100px"
        }}
      >
        <Grid container className="AboutHeaderContainer">
          <Grid item xs={2} style={{ position: "relative" }}>
            <img src={Stars4} alt="Stars" className="StarsLeft" />
          </Grid>
          <Grid item xs={8}>
            <h1 className="AbtHeader">Honorary Tubby Cats</h1>
            <p
              className="LightPink"
              style={{
                margin: "0 auto",
                textAlign: "center",
                marginBottom: "80px"
              }}
            >
              Honorary Tubby Cats are created for....
            </p>
          </Grid>
          <Grid item xs={2} style={{ position: "relative" }}>
            <img src={Stars3} alt="Stars" className="StarsRight" />
          </Grid>
        </Grid>
        <Grid container spacing={4} style={{ marginBottom: "300px" }}>
          {honMembers.map((member, i) => (
            <Grid item xs={12} sm={4} md={3} lg={2} className="HonMember">
              <img src={member._img} alt="honorary" style={{ width: "100%" }} />
              <a
                href={`https://twitter.com/${member.twitterHandle}`}
                target="_blank"
                rel="noreferrer"
              >
                @{member.twitterHandle}
              </a>
            </Grid>
          ))}
        </Grid>
      </div>
      <div
        style={{
          height: "300px",
          width: "100%",
          display: "block",
          margin: "0 auto"
        }}
      ></div>
      <div
        style={{
          height: "300px",
          width: "100%",
          display: "block",
          margin: "0 auto"
        }}
      ></div>
    </div>
  )
}
export default Honoraries
